import {
  cloneElement,
  createContext,
  createRef,
  CSSProperties,
  forwardRef,
  Fragment,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipV3 } from '../../TooltipV3';
import {
  autoUpdate,
  flip,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import { useMergeRefs } from '@floating-ui/react';
import { simplify, roundToLong } from '../../../Root/Main/shared';
import { ForecastTrackingData, ImpactTracking, TargetType, Targets } from '../../../api';
import { ImpactValueType } from '../../../Root/Main/Products/Report/Sku/Overview';
import { format, isSameYear, subYears } from 'date-fns';
import { getTrackingData } from './converter';
import { formatPeriod } from '../../../Root/Main/Dashboard/functions';
import MultiRef from 'react-multi-ref';
import cn from 'classnames';
import sortBy from 'lodash/sortBy';

interface Props {
  disabled?: boolean;
  small?: boolean;
  targets: Targets;
  selectedImpact: { id: string; name: string };
  selectedType: ImpactValueType;
  impacts: ImpactTracking[];
  isParentModal?: boolean;
}

interface GeneralProps {
  yAxisDomainMax: number;
  disabled?: boolean;
  small?: boolean;
  selectedImpact: { id: string; name: string };
  selectedType: ImpactValueType;
  containerRef: RefObject<HTMLDivElement>;
  isParentModal: boolean;
}

export const ProgressChart = (props: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const yearRefs = useRef<MultiRef<string, HTMLDivElement>>(new MultiRef());
  const [, forceRender] = useReducer((s) => s + 1, 0);
  const [targetMarkerPosition, setTargetMarkerPosition] = useState<'left' | 'right' | 'inside' | undefined>();
  const targetMarkerRef = useRef<HTMLDivElement>(null);
  const baselineMarkerRef = useRef<HTMLDivElement>(null);
  const [baselineMarkerPosition, setBaselineMarkerPosition] = useState<'left' | 'right' | 'inside' | undefined>();
  const rootRef = useRef<HTMLDivElement>(null);

  const timeframeByYear = getTrackingData(props.impacts.find((impact) => impact.id === props.selectedImpact.id)!, props.targets);

  useEffect(() => {
    // needed to redraw target line when switching between methodologies
    if (containerRef.current) {
      forceRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  useEffect(() => {
    if (yearRefs.current.map.size > 0 && !props.disabled) {
      yearRefs.current.map.forEach((ref, key) => {
        if (isSameYear(new Date(key), subYears(new Date(), 2))) {
          ref.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'center' });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearRefs]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const rect = entry.boundingClientRect;
        const rootRect = entry.rootBounds;

        if (!rootRect) {
          return;
        }
        if (rect.right < rootRect.left) {
          setBaselineMarkerPosition('left');
        } else if (rect.left > rootRect.right) {
          setBaselineMarkerPosition('right');
        } else {
          setBaselineMarkerPosition('inside');
        }
      },
      {
        root: rootRef.current,
        rootMargin: '0px',
        threshold: 0,
      },
    );

    if (baselineMarkerRef.current) {
      observer.observe(baselineMarkerRef.current);
    }

    if (!baselineMarkerRef.current) {
      setBaselineMarkerPosition(undefined);
    }

    return () => {
      observer.disconnect();
      setBaselineMarkerPosition(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baselineMarkerRef, props.selectedImpact, yearRefs.current.map, containerRef.current]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const rect = entry.boundingClientRect;
        const rootRect = entry.rootBounds;

        if (!rootRect) {
          return;
        }
        if (rect.right < rootRect.left) {
          setTargetMarkerPosition('left');
        } else if (rect.left > rootRect.right) {
          setTargetMarkerPosition('right');
        } else {
          setTargetMarkerPosition('inside');
        }
      },
      {
        root: rootRef.current,
        rootMargin: '0px',
        threshold: 0,
      },
    );

    if (targetMarkerRef.current) {
      observer.observe(targetMarkerRef.current);
    }

    if (!targetMarkerRef.current) {
      setTargetMarkerPosition(undefined);
    }

    return () => {
      observer.disconnect();
      setTargetMarkerPosition(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetMarkerRef, props.selectedImpact, yearRefs.current.map, containerRef.current]);

  const getValueOfSelectedType = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;

  const getUnit = (options?: { short: boolean }) =>
    props.selectedType === ImpactValueType.Physical
      ? props.impacts.find((impact) => impact.id === props.selectedImpact.id)!.unit
      : options?.short
      ? 'impact p.'
      : 'impact points';

  const yAxisDomainMax =
    getValueOfSelectedType({
      points: Math.max(
        ...timeframeByYear.data.flatMap(({ dataset }) =>
          dataset.filter((item) => item.type !== 'estimation').reduce((acc, item) => acc + item.points!, 0),
        ),
      ),
      physical: Math.max(
        ...timeframeByYear.data.flatMap(({ dataset }) =>
          dataset.filter((item) => item.type !== 'estimation').reduce((acc, item) => acc + (item.physical ?? 0), 0),
        ),
      ),
    }) * 1.1;

  const generalProps = {
    yAxisDomainMax,
    containerRef,
    disabled: props.disabled,
    small: props.small,
    selectedImpact: props.selectedImpact,
    selectedType: props.selectedType,
  };

  const yAxis = () => (
    <div className='absolute h-full w-full pb-12 flex flex-col justify-between bottom-0 divide-y pointer-events-none z-[1]'>
      <div
        className={cn(
          'absolute top-1/2 text-zinc-400 size-0 whitespace-nowrap -left-[72px]',
          props.selectedType === 'impactPoints' && 'text-[10px] uppercase',
        )}
      >
        <div className='absolute -rotate-90 -translate-y-1/2 -translate-x-1/2 left-0'>{getUnit()}</div>
      </div>
      {new Array(6).fill(null).map((_, i, arr) => {
        const rawValue = yAxisDomainMax - (yAxisDomainMax / (arr.length - 1)) * i;
        return (
          <div key={i} className='w-full'>
            <div className='absolute left-0'>
              <div
                title={roundToLong(rawValue)}
                className={cn(
                  props.small ? 'text-[10px]' : 'text-xs',
                  '-translate-y-1/2 -translate-x-full pr-1.5 text-zinc-400',
                  props.disabled ? 'pointer-events-none' : 'pointer-events-auto',
                )}
              >
                {i !== arr.length - 1 && simplify(rawValue)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  const forecastLine = (forecast?: ForecastTrackingData) => {
    if (!forecast) {
      return undefined;
    }

    const forecastImpactValue = getValueOfSelectedType({ points: forecast.totalImpact.points, physical: forecast.totalImpact.physical! });

    const forecastLinePosition = (forecastImpactValue / yAxisDomainMax) * 100;

    return (
      <div
        className='absolute z-[3] pointer-events-none'
        style={{ width: containerRef.current!.offsetWidth, height: containerRef.current!.offsetHeight }}
      >
        <div
          className='right-0 left-0 border-t-2 border-dashed border-amber-400 absolute'
          style={
            {
              // since forecast impact value can be higher than the max value of the y-axis, we need to prevent it from going out of the chart
              bottom: `${forecastLinePosition > 99 ? 99 : forecastLinePosition}%`,
            } as CSSProperties
          }
        ></div>
      </div>
    );
  };

  return (
    <div className='relative h-full'>
      <div className='absolute h-full top-0 bottom-0 left-0 right-0'>
        {yAxis()}
        <div className='absolute flex justify-center bottom-3 left-0 w-full text-zinc-400 uppercase tracking-wide text-xs'>
          calendar years
        </div>
        <BaselineBadge
          {...generalProps}
          unit={getUnit({ short: true })}
          baseline={timeframeByYear.data[0].dataset.find((item) => item.type === 'baseline')!}
          baselineMarkerRef={baselineMarkerRef}
          baselineMarkerPosition={baselineMarkerPosition}
          isParentModal={!!props.isParentModal}
        />
        <TargetBadge
          {...generalProps}
          targetMarkerRef={targetMarkerRef}
          targetData={timeframeByYear.data[0].targetData}
          targetMarkerPosition={targetMarkerPosition}
          impacts={props.impacts}
          isParentModal={!!props.isParentModal}
        />
        <div ref={rootRef} className={cn('relative h-full pb-12', props.small ? 'overflow-hidden' : 'overflow-x-auto overflow-y-hidden')}>
          <div className='absolute h-full w-full'>
            <TargetLines
              {...generalProps}
              isParentModal={!!props.isParentModal}
              timeframeByYear={timeframeByYear}
              impacts={props.impacts}
              targetMarkerRef={targetMarkerRef}
            />
            {containerRef.current && forecastLine(props.impacts.find((impact) => impact.id === props.selectedImpact.id)?.forecast)}
            <TargetGuidanceLine
              {...generalProps}
              targetMarkerRef={targetMarkerRef}
              targetData={timeframeByYear.data[0].targetData}
              targetMarkerPosition={targetMarkerPosition}
            />
            <BaselineGuidanceLine
              {...generalProps}
              baselineMarkerRef={baselineMarkerRef}
              baselineMarkerPosition={baselineMarkerPosition}
              timeframeByYear={timeframeByYear}
              isParentModal={!!props.isParentModal}
            />
          </div>
          <div
            ref={containerRef}
            className={cn(
              'inline-flex items-baseline gap-x-px h-full relative',
              props.disabled ? 'pointer-events-none' : 'pointer-events-auto',
            )}
          >
            {timeframeByYear.data.map((entity, yearIndex) => (
              <div key={yearIndex} className={cn('flex flex-col-reverse h-full w-16')}>
                {(() => {
                  const scaledSum = entity.dataset.reduce(
                    (acc, item) => acc + getValueOfSelectedType({ points: item.points!, physical: item.physical! }),
                    0,
                  );

                  return (
                    <Popover
                      key={yearIndex}
                      offsetCross={0}
                      ref={yearRefs.current.ref(`${entity.year}`)}
                      offset={-yearRefs.current.map.get(`${entity.year}`)?.offsetWidth! / 2}
                      disabled={props.disabled}
                      isParentModal={!!props.isParentModal}
                      content={() => (
                        <PopoverContent
                          value={entity.dataset.reduce(
                            (acc, item) => acc + getValueOfSelectedType({ points: item.points!, physical: item.physical! }),
                            0,
                          )}
                          unit={getUnit({ short: true })}
                          year={entity.year}
                          data={sortBy(entity.dataset, 'start')}
                          small={props.small}
                          selectedType={props.selectedType}
                          selectedImpact={props.selectedImpact}
                        />
                      )}
                    >
                      {({ open }) => (
                        <div
                          className={cn('relative border border-transparent hover:cursor-pointer hover:border-brand hover:z-[1]', {
                            'pointer-events-none': (scaledSum / yAxisDomainMax) * 100 === 0 || props.disabled,
                            'z-[1]': open,
                          })}
                          style={
                            {
                              height: `${(scaledSum / yAxisDomainMax) * 100}%`,
                              maxHeight: '90%',
                            } as CSSProperties
                          }
                          key={yearIndex}
                        >
                          <div
                            className={cn(
                              'absolute text-zinc-400 z-[1] -translate-x-1/2 left-1/2 whitespace-nowrap',
                              props.small ? 'text-[9px] -top-3.5' : 'text-xs -top-5',
                            )}
                          >
                            {(() => {
                              const totalValue = entity.dataset.reduce(
                                (acc, item) => acc + getValueOfSelectedType({ points: item.points!, physical: item.physical! }),
                                0,
                              );
                              return totalValue > 0 ? simplify(totalValue) : '';
                            })()}
                          </div>
                          <div className='absolute -bottom-4 text-center w-full text-zinc-400 text-xs z-[1]'>
                            {format(entity.year, 'yyyy')}
                          </div>
                          {sortBy(entity.dataset, 'start')
                            .sort((a) => (['report', 'baseline'].includes(a.type) ? 1 : -1))
                            .map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className={cn(
                                    'relative w-full',
                                    ['report', 'baseline'].includes(item.type) && item.colors.secondary,
                                    item.type === 'gap' && item.colors.empty,
                                    item.type === 'estimation' && 'bg-zinc-100',
                                  )}
                                  style={
                                    {
                                      height: `${
                                        (getValueOfSelectedType({ points: item.points!, physical: item.physical! }) / scaledSum) * 100
                                      }%`,
                                    } as CSSProperties
                                  }
                                >
                                  {yearIndex === 0 && i === 0 && (
                                    <div ref={baselineMarkerRef} className='relative -top-[5px] z-[2]'>
                                      <div className='absolute border-l-[5px] border-l-brandDarkPurple2 border-t-[4px] border-b-[4px] border-r-brandDarkPurple2 border-transparent' />
                                      <div className='absolute top-[3px] h-[2px] bg-brandDarkPurple2 w-full' />
                                      <div className='absolute right-0 border-r-[5px] border-r-brandDarkPurple2 border-t-[4px] border-b-[4px] border-transparent' />
                                    </div>
                                  )}
                                  {item.type === 'gap' && (
                                    <>
                                      <svg className='absolute' width='100%' height='100%'>
                                        <pattern
                                          width='6'
                                          height='6'
                                          id={`pattern_${i}`}
                                          patternTransform='rotate(45)'
                                          patternUnits='userSpaceOnUse'
                                        >
                                          <line x1='0' y='0' x2='0' y2='6' strokeWidth='4' stroke={item.colors.hex} />
                                        </pattern>
                                        <rect fill={`url(#pattern_${i})`} width='100%' height='100%' />
                                      </svg>
                                      <div
                                        onClick={(e) => e.preventDefault()}
                                        className={cn(
                                          props.small ? 'text-[9px]' : 'text-xs',
                                          'absolute -top-5 left-1/2 -translate-x-1/2 text-zinc-400 z-[2] pointer-events-none',
                                        )}
                                      />
                                    </>
                                  )}
                                  <div
                                    onClick={(e) => e.preventDefault()}
                                    className={cn(
                                      'absolute -top-5 left-1/2 -translate-x-1/2 text-zinc-700 z-[2] pointer-events-none',
                                      props.small ? 'text-[9px]' : 'text-xs',
                                    )}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </Popover>
                  );
                })()}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface BaselineBadgeProps extends GeneralProps {
  baseline: {
    colors: { primary: string; secondary: string; empty: string; hex: string };
    type: string;
    start?: Date | undefined;
    end?: Date | undefined;
    points?: number | undefined;
    physical?: number | undefined;
  };
  unit: string;
  baselineMarkerRef: RefObject<HTMLDivElement>;
  baselineMarkerPosition: 'left' | 'right' | 'inside' | undefined;
}

const BaselineBadge = (props: BaselineBadgeProps) => {
  if (!props.containerRef.current || !props.baseline.points) {
    return <></>;
  }

  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === ImpactValueType.Physical && props.selectedImpact.id !== 'overall' ? physical : points;
  const valueToPx = (value: number) => ((100 - (value / props.yAxisDomainMax) * 100) / 100) * props.containerRef.current!.offsetHeight;

  const y = valueToPx(getValue({ points: props.baseline.points!, physical: props.baseline.physical! }));

  return (
    <Popover
      offset={10}
      isParentModal={props.isParentModal}
      content={() => (
        <PopoverContent
          selectedType={props.selectedType}
          selectedImpact={props.selectedImpact}
          value={getValue({ points: props.baseline.points!, physical: props.baseline.physical! })}
          unit={props.unit}
          year={props.baseline.start!}
          data={[props.baseline]}
        />
      )}
    >
      {() => (
        <div
          style={{
            top: `${y}px`,
          }}
          className={cn(
            'flex items-center gap-x-1 absolute z-[3] cursor-pointer -translate-y-full text-brandDarkPurple2',
            { hidden: props.baselineMarkerPosition === 'inside' },
            props.baselineMarkerPosition === 'left' && 'left-0 ml-1',
            props.baselineMarkerPosition === 'right' && 'right-0 mr-1',
          )}
        >
          {props.baselineMarkerPosition === 'left' && (
            <FontAwesomeIcon className={cn('rotate-180', props.small ? 'size-3' : 'size-4')} icon={light('arrow-right')} />
          )}
          <div className={cn(props.small && 'text-[10px]')}>Baseline</div>
          {props.baselineMarkerPosition === 'right' && (
            <FontAwesomeIcon className={cn('rotate-180', props.small ? 'size-3' : 'size-4')} icon={light('arrow-right')} />
          )}
        </div>
      )}
    </Popover>
  );
};

interface BaselineGuidanceLineProps extends GeneralProps {
  baselineMarkerRef: RefObject<HTMLDivElement>;
  baselineMarkerPosition: 'left' | 'right' | 'inside' | undefined;
  timeframeByYear: ReturnType<typeof getTrackingData>;
}

const BaselineGuidanceLine = (props: BaselineGuidanceLineProps) => {
  const [, forceRender] = useReducer((s) => s + 1, 0);

  useEffect(() => {
    forceRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timeframeByYear.data, props.containerRef.current, props.baselineMarkerRef.current]);

  if (!props.containerRef.current || !props.baselineMarkerRef.current) {
    return <></>;
  }

  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;
  const impactValueToPx = (value: number) =>
    ((100 - (value / props.yAxisDomainMax) * 100) / 100) * props.containerRef.current!.offsetHeight;

  const linePosition = impactValueToPx(
    getValue({
      points: props.timeframeByYear.data[0].dataset.find(({ type }) => type === 'baseline')!.points!,
      physical: props.timeframeByYear.data[0].dataset.find(({ type }) => type === 'baseline')!.physical!,
    }),
  );

  return (
    <div
      className='absolute left-0 right-0 z-[3] pointer-events-none'
      style={
        {
          width: props.containerRef.current!.offsetWidth,
          top: `${linePosition}px`,
        } as CSSProperties
      }
    >
      <div className='absolute right-0 left-0 border-t-[1px] -translate-y-1/2 border-dashed border-dark'></div>
    </div>
  );
};

interface TargetGuidanceBadgeProps extends GeneralProps {
  impacts: ImpactTracking[];
  targetMarkerRef: RefObject<HTMLDivElement>;
  targetMarkerPosition: 'left' | 'right' | 'inside' | undefined;
  targetData?: {
    start: Date;
    end: Date;
    points: number;
    physical: number;
    stepPoints: number;
    stepPhysical: number;
    estimate: string;
    type: TargetType;
    additionalData?: {
      reduction?: number | null;
      estimate: string;
      target?: Date;
    };
  };
}

const TargetBadge = (props: TargetGuidanceBadgeProps) => {
  if (!props.containerRef.current || !props.targetMarkerRef.current || !props.targetData || !props.selectedImpact || !props.selectedType) {
    return <></>;
  }

  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;
  const valueToPx = (value: number) => ((100 - (value / props.yAxisDomainMax) * 100) / 100) * props.containerRef.current!.offsetHeight;

  const y = valueToPx(getValue({ points: props.targetData.points, physical: props.targetData.physical }));

  const popover = (children: ReactNode) => {
    if (props.targetData?.type === TargetType.LONG_TERM) {
      return (
        <TargetPopover
          isParentModal={props.isParentModal}
          popoverPosition={{
            x: -40,
            y: 0,
          }}
          content={
            <TargetPopoverLongTermContent
              small={props.small}
              selectedImpact={props.selectedImpact}
              selectedType={props.selectedType}
              reduction={props.targetData.additionalData!.reduction!}
              targetData={props.targetData}
              impacts={props.impacts}
            />
          }
        >
          {children}
        </TargetPopover>
      );
    }

    if (props.targetData?.type === TargetType.YEAR_OVER_YEAR) {
      return (
        <TargetPopover
          isParentModal={props.isParentModal}
          content={
            <TargetPopoverYearOverYearContent
              small={props.small}
              selectedImpact={props.selectedImpact}
              selectedType={props.selectedType}
              targetData={props.targetData}
              impacts={props.impacts}
            />
          }
        >
          {children}
        </TargetPopover>
      );
    }

    return <></>;
  };

  return (
    <div
      style={
        {
          top: `${y}px`,
        } as CSSProperties
      }
      className={cn(
        'absolute z-[3] -translate-y-full text-fuchsia-400',
        { hidden: props.targetMarkerPosition === 'inside' },
        props.targetMarkerPosition === 'left' && 'left-0 ml-1',
        props.targetMarkerPosition === 'right' && 'right-0 mr-1',
      )}
    >
      {popover(
        <div className='flex items-center gap-x-1 '>
          {props.targetMarkerPosition === 'left' && (
            <FontAwesomeIcon className={cn('rotate-180', props.small ? 'size-3' : 'size-4')} icon={light('arrow-right')} />
          )}
          {props.targetMarkerPosition && ['left', 'right'].includes(props.targetMarkerPosition) && (
            <div className={cn(props.small && 'text-[10px]')}>
              {props.targetData.additionalData?.target && format(new Date(props.targetData.additionalData?.target), 'yyyy')} Target
            </div>
          )}
          {props.targetMarkerPosition === 'right' && (
            <FontAwesomeIcon className={cn(props.small ? 'size-3' : 'size-4')} icon={light('arrow-right')} />
          )}
        </div>,
      )}
    </div>
  );
};

interface TargetGuidanceLineProps {
  yAxisDomainMax: number;
  selectedImpact: { id: string; name: string };
  selectedType: 'physical' | 'impactPoints';
  containerRef: RefObject<HTMLDivElement>;
  targetMarkerRef: RefObject<HTMLDivElement>;
  targetData?: {
    start: Date;
    end: Date;
    points: number;
    physical: number;
    type: TargetType;
  };
  targetMarkerPosition: 'left' | 'right' | 'inside' | undefined;
}

const TargetGuidanceLine = (props: TargetGuidanceLineProps) => {
  const [, forceRender] = useReducer((s) => s + 1, 0);

  useEffect(() => {
    forceRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.targetData, props.containerRef.current, props.targetMarkerRef.current]);

  if (!props.targetData || !props.containerRef.current || !props.targetMarkerRef.current) {
    return <></>;
  }

  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;
  const valueToPx = (value: number) => ((100 - (value / props.yAxisDomainMax) * 100) / 100) * props.containerRef.current!.offsetHeight;

  const linePosition = valueToPx(getValue({ points: props.targetData.points, physical: props.targetData.physical }));

  return (
    <div className='absolute z-[2] pointer-events-none' style={{ width: props.containerRef.current!.offsetWidth }}>
      <div
        className='right-0 left-0 border-t-[1px] -translate-y-1/2 border-dashed border-fuchsia-400 absolute'
        style={
          {
            top: `${linePosition}px`,
          } as CSSProperties
        }
      ></div>
    </div>
  );
};

interface PopoverContentProps {
  year: Date;
  data: {
    start?: Date | undefined;
    end?: Date | undefined;
    colors: { primary: string; secondary: string; empty: string; hex: string };
    type: string;
    points?: number;
    physical?: number;
  }[];
  value: number;
  unit: string;
  small?: boolean;
  selectedType: ImpactValueType;
  selectedImpact: { id: string; name: string };
}

const PopoverContent = (props: PopoverContentProps) => {
  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;

  return (
    <div
      className={cn(
        'shadow-[-1px_0px_3px_2px_rgba(0,0,0,0.10)] rounded-lg',
        props.data.some((item) => item.type === 'baseline')
          ? 'bg-brandDarkPurple2'
          : props.data.some((item) => item.type === 'report')
          ? props.data[0].colors.primary
          : props.data[0].colors.empty,
      )}
    >
      <div className='bg-white rounded-lg ml-[3px] px-3 py-2'>
        <div className='flex flex-col gap-y-2 bg-white text-zinc-800'>
          {props.data.find((item) => item.type === 'baseline') && <div className='font-semibold'>Overall baseline</div>}
          {props.data.find((item) => item.type === 'report') && <div className='font-semibold'>Historical data</div>}
          {!props.data.find(({ type }) => ['baseline', 'report'].includes(type)) && props.data.some((item) => item.type === 'gap') && (
            <div className='font-semibold'>No data available</div>
          )}
          {!props.data.some(({ type }) => ['baseline', 'report', 'gap'].includes(type)) &&
            props.data.some((item) => item.type === 'estimation') && <div className='font-semibold'>Estimated progress</div>}
          <div className='grid grid-cols-2 gap-y-2 gap-x-4 items-center'>
            {props.data.map((item, i) => (
              <Fragment key={i}>
                <div className='text-zinc-500' key={i}>
                  {formatPeriod(item.start!, item.end!)}
                </div>
                <div
                  className={cn('flex items-center gap-x-2', ['report', 'baseline'].includes(item.type) ? 'text-dark' : 'text-zinc-400')}
                >
                  <div className={cn(props.small ? 'text-[10px]' : 'text-sm')}>
                    {simplify(getValue({ points: item.points!, physical: item.physical! }))}
                  </div>
                  <div className='flex gap-x-2 items-center'>
                    <div className='text-[10px] uppercase'>{props.unit}</div>

                    {item.type === 'gap' && (
                      <TooltipV3
                        placement='right-end'
                        content={
                          <div className='p-2 bg-amber-50 border border-amber-400 rounded-lg text-zinc-800 w-64 whitespace-normal'>
                            No actual data available. This value was assumed based on all actual historical data provided for the previous
                            or current year up until this timeframe.
                          </div>
                        }
                      >
                        <div className='flex size-5'>
                          <div className='size-5 flex items-center justify-center bg-amber-50 rounded-full cursor-pointer'>
                            <FontAwesomeIcon className='size-3 text-amber-400' icon={regular('exclamation-triangle')} />
                          </div>
                        </div>
                      </TooltipV3>
                    )}
                    {props.data.some(({ type }) => type === 'estimation') && item.type === 'estimation' && (
                      <TooltipV3
                        placement='right-end'
                        content={
                          <div className='p-2 bg-slate-200 border border-brand rounded-lg text-zinc-800 w-48 whitespace-normal'>
                            Estimated progress, based on past trend.
                          </div>
                        }
                      >
                        <div className='flex size-5'>
                          <div className='size-5 flex items-center justify-center bg-slate-200 rounded-full cursor-pointer'>
                            <FontAwesomeIcon className='size-3 text-brand' icon={regular('clock-rotate-left')} />
                          </div>
                        </div>
                      </TooltipV3>
                    )}
                  </div>
                </div>
              </Fragment>
            ))}
            {!props.data.every(({ type }) => type === 'estimation') && (
              <>
                <div className='col-span-2 bg-zinc-200 h-px'></div>
                <Fragment>
                  <div className='text-zinc-500 font-semibold'>{format(props.year, 'yyyy')} total</div>
                  <div
                    className={cn('flex items-center gap-x-2', {
                      'text-zinc-400': props.data.some(({ type }) => !['baseline', 'report'].includes(type)),
                    })}
                  >
                    <div className={cn(props.small ? 'text-[10px]' : 'text-sm')}>{simplify(props.value)}</div>
                    <div className='text-[10px] uppercase'>{props.unit}</div>
                  </div>
                </Fragment>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface TargetPopoverProps {
  isParentModal: boolean;
  content: ReactNode;
  popoverPosition?: { x: number; y: number };
  setIsOpened?: (value: boolean) => void;
  disabled?: boolean;
  small?: boolean;
}

const TargetPopover = (props: PropsWithChildren<TargetPopoverProps>) => {
  return (
    <Popover
      isParentModal={props.isParentModal}
      offset={props.popoverPosition?.x}
      offsetCross={props.popoverPosition?.y}
      setIsOpened={props.setIsOpened}
      content={() => props.content}
      disabled={props.disabled}
    >
      {() => <button className={cn(props.disabled ? 'pointer-events-none' : 'pointer-events-auto')}>{props.children}</button>}
    </Popover>
  );
};

const Context = createContext<{ x: number; y: number }>({
  x: Infinity,
  y: Infinity,
});

const MouseTracker = (props: PropsWithChildren<{}>) => {
  const ref = createRef<HTMLDivElement>();
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: Infinity, y: Infinity });
  const animationFrame = useRef<number>(Infinity);

  useEffect(() => {
    const wrapper = ref.current!;

    if (wrapper) {
      const mousemove = ({ x, y }: MouseEvent) => {
        animationFrame.current = requestAnimationFrame(() =>
          setPosition({
            x: x - wrapper.getBoundingClientRect().left,
            y: y - wrapper.getBoundingClientRect().top,
          }),
        );
      };

      wrapper.addEventListener('mousemove', mousemove);

      return () => {
        wrapper.removeEventListener('mousemove', mousemove);
        cancelAnimationFrame(animationFrame.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <Context.Provider value={position}>
      <div onMouseLeave={() => setPosition({ x: Infinity, y: Infinity })} ref={ref} className='absolute'>
        {props.children}
      </div>
    </Context.Provider>
  );
};

interface TargetPopoverLongTermContentProps {
  reduction: number;
  targetData: {
    points: number;
    physical: number;
    additionalData?: {
      estimate: string;
      target?: Date;
    };
  };
  selectedImpact: { id: string; name: string };
  selectedType: 'physical' | 'impactPoints';
  small?: boolean;
  impacts: ImpactTracking[];
}

const TargetPopoverLongTermContent = (props: TargetPopoverLongTermContentProps) => {
  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;

  const getUnit = (options?: { short: boolean }) =>
    props.selectedType === 'physical'
      ? props.impacts.find((impact) => impact.id === props.selectedImpact.id)!.unit
      : options?.short
      ? 'impact p.'
      : 'impact points';

  return (
    <div className='relative flex flex-col gap-y-2 px-3 py-2 bg-white rounded-lg border border-fuchsia-400 shadow text-xs'>
      <div className='font-semibold'>Overall target</div>
      <div className='text-zinc-500'>{props.reduction}% compared to baseline</div>
      <div className='flex justify-between gap-x-6 items-center w-full border-t pt-1.5'>
        <div className='flex gap-x-1 whitespace-nowrap items-center'>
          <div className={cn('text-zinc-900', props.small ? 'text-[10px]' : 'text-sm')}>
            {simplify(getValue({ points: props.targetData!.points, physical: props.targetData!.physical }))}
          </div>
          <div
            className={cn('text-zinc-600 text-[10px] mt-0.5', {
              uppercase: props.selectedType !== 'physical',
            })}
          >
            {getUnit({ short: true })}
          </div>
        </div>
        <div className='text-zinc-500 font-semibold whitespace-nowrap'>
          by {format(new Date(props.targetData.additionalData!.target!), 'yyyy')}
        </div>
      </div>
    </div>
  );
};

interface TargetPopoverYearOverYearContentProps {
  selectedType: 'physical' | 'impactPoints';
  selectedImpact: { id: string; name: string };
  impacts: ImpactTracking[];
  targetData: {
    points: number;
    physical: number;
    type: TargetType;
    stepPoints: number;
    stepPhysical: number;
    estimate: string;
    additionalData?: {
      target?: Date;
      reduction?: number | null;
      estimate: string;
    };
  };
  small?: boolean;
}

const TargetPopoverYearOverYearContent = (props: TargetPopoverYearOverYearContentProps) => {
  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;

  const getUnit = (options?: { short: boolean }) =>
    props.selectedType === 'physical'
      ? props.impacts.find((impact) => impact.id === props.selectedImpact.id)!.unit
      : options?.short
      ? 'impact p.'
      : 'impact points';

  return (
    <div className='relative flex flex-col gap-y-2 px-3 py-2 bg-white rounded-lg border border-fuchsia-400 shadow text-xs'>
      <div className='font-semibold'>Overall target</div>
      <div className='text-zinc-500'>{props.targetData!.estimate}</div>
      <div className='flex justify-between gap-x-6 items-center w-full border-t pt-1.5'>
        <div className='flex gap-x-1 whitespace-nowrap items-center'>
          <div className={cn('text-zinc-900', props.small ? 'text-[10px]' : 'text-sm')}>
            {simplify(getValue({ points: props.targetData!.stepPoints, physical: props.targetData!.stepPhysical }))}
          </div>
          <div className='uppercase text-zinc-600 text-[10px] mt-0.5'>{getUnit({ short: true })}</div>
        </div>
        <div className='text-zinc-500 font-semibold whitespace-nowrap'>per year</div>
      </div>
    </div>
  );
};

interface TargetLinesProps {
  disabled?: boolean;
  small?: boolean;
  yAxisDomainMax: number;
  selectedImpact: { id: string; name: string };
  selectedType: 'physical' | 'impactPoints';
  containerRef: RefObject<HTMLDivElement>;
  timeframeByYear: ReturnType<typeof getTrackingData>;
  impacts: ImpactTracking[];
  targetMarkerRef: RefObject<HTMLDivElement>;
  isParentModal: boolean;
}

const TargetLines = (props: TargetLinesProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [mouseCoords, setMouseCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [, reRender] = useReducer((s) => s + 1, 0);

  useLayoutEffect(() => {
    reRender();
  }, [props.selectedImpact, props.selectedType, props.containerRef]);

  useEffect(() => {
    setHovered(isOpened);
  }, [isOpened]);

  if (!props.containerRef.current) {
    return <></>;
  }

  const stepX = props.containerRef.current!.offsetWidth! / props.timeframeByYear.data.length;
  const valueToPx = (value: number) => ((100 - (value / props.yAxisDomainMax) * 100) / 100) * props.containerRef.current!.offsetHeight;
  const getValue = ({ points, physical }: { points: number; physical: number }) =>
    props.selectedType === 'physical' && props.selectedImpact.id !== 'overall' ? physical : points;

  return (
    <MouseTracker>
      <Context.Consumer>
        {(coords) => (
          <div
            className='absolute z-[3] pointer-events-none overflow-y-hidden'
            style={{ width: props.containerRef.current!.offsetWidth, height: props.containerRef.current!.offsetHeight }}
          >
            {props.timeframeByYear.data.map((year, i) => {
              const containerHeight = props.containerRef.current!.offsetHeight;

              if (year.targetData) {
                const currentLineOffsetTop = valueToPx(getValue({ points: year.targetData.points, physical: year.targetData.physical }));

                const offsetBottom = 10;
                if (currentLineOffsetTop < containerHeight - offsetBottom) {
                  if (year.targetData.type === TargetType.LONG_TERM && year.targetData.additionalData?.target) {
                    const sameYear = isSameYear(year.year, new Date(year.targetData.additionalData.target));
                    return (
                      <Fragment key={i}>
                        {sameYear && (
                          <TargetPopover
                            isParentModal={props.isParentModal}
                            small={props.small}
                            disabled={props.disabled}
                            popoverPosition={mouseCoords}
                            setIsOpened={setIsOpened}
                            content={
                              <TargetPopoverLongTermContent
                                small={props.small}
                                selectedImpact={props.selectedImpact}
                                selectedType={props.selectedType}
                                reduction={year.targetData.additionalData.reduction!}
                                targetData={year.targetData}
                                impacts={props.impacts}
                              />
                            }
                          >
                            <div
                              onClick={() => setMouseCoords(coords)}
                              onMouseEnter={() => !isOpened && setHovered(true)}
                              onMouseLeave={() => !isOpened && setHovered(false)}
                              ref={props.targetMarkerRef}
                              className={cn(
                                'flex items-center hover:cursor-pointer -translate-y-1/2',
                                props.disabled ? 'pointer-events-none' : 'pointer-events-auto',
                              )}
                              style={{
                                position: 'absolute',
                                left: `${stepX * i}px`,
                                top: `${currentLineOffsetTop}px`,
                                width: `${stepX}px`,
                                height: '10px',
                                backgroundColor: hovered ? '#E0E7FF' : 'transparent',
                                zIndex: 1,
                              }}
                            >
                              <div
                                style={{
                                  zIndex: 1,
                                }}
                                className='absolute w-full h-[1px] bg-fuchsia-400'
                              >
                                <>
                                  <div className='absolute border-l-[5px] -translate-y-1/2 border-l-fuchsia-400 border-t-[4px] border-b-[4px] border-r-fuchsia-400 border-transparent' />
                                  <div className='absolute right-0 border-r-[5px] -translate-y-1/2 border-r-fuchsia-400 border-t-[4px] border-b-[4px] border-transparent' />
                                </>
                              </div>
                            </div>
                          </TargetPopover>
                        )}
                      </Fragment>
                    );
                  }

                  if (year.targetData.type === TargetType.YEAR_OVER_YEAR && i > 0) {
                    return (
                      <Fragment key={i}>
                        <TargetPopover
                          isParentModal={props.isParentModal}
                          small={props.small}
                          disabled={props.disabled}
                          popoverPosition={mouseCoords}
                          setIsOpened={setIsOpened}
                          content={
                            <TargetPopoverYearOverYearContent
                              selectedType={props.selectedType}
                              selectedImpact={props.selectedImpact}
                              impacts={props.impacts}
                              targetData={year.targetData}
                              small={props.small}
                            />
                          }
                        >
                          {year?.targetData && (
                            <Fragment key={i}>
                              <div
                                onMouseEnter={() => !isOpened && setHovered(true)}
                                onMouseLeave={() => !isOpened && setHovered(false)}
                                onClick={() => setMouseCoords(coords)}
                                className={cn(
                                  'flex items-center hover:cursor-pointer -translate-y-1/2',
                                  props.disabled ? 'pointer-events-none' : 'pointer-events-auto',
                                )}
                                style={{
                                  position: 'absolute',
                                  left: `${stepX * i}px`,
                                  top: `${currentLineOffsetTop}px`,
                                  width: `${stepX}px`,
                                  height: '8px',
                                  backgroundColor: hovered ? '#E0E7FF' : 'transparent',
                                  zIndex: 1,
                                }}
                              >
                                <div
                                  style={{
                                    zIndex: 1,
                                  }}
                                  className='absolute w-full h-[1px] bg-fuchsia-400'
                                ></div>
                              </div>
                              {(() => {
                                const nextLineTopOffset =
                                  props.timeframeByYear.data[i + 1] &&
                                  valueToPx(
                                    props.selectedType === ImpactValueType.Physical
                                      ? props.timeframeByYear.data[i + 1].targetData!.physical
                                      : props.timeframeByYear.data[i + 1].targetData!.points,
                                  );

                                return (
                                  nextLineTopOffset && (
                                    <div
                                      onMouseEnter={() => !isOpened && setHovered(true)}
                                      onMouseLeave={() => !isOpened && setHovered(false)}
                                      onClick={() => setMouseCoords(coords)}
                                      className={cn('hover:cursor-pointer', props.disabled ? 'pointer-events-none' : 'pointer-events-auto')}
                                      style={{
                                        position: 'absolute',
                                        left: `${stepX * (i + 1) - 4}px`,
                                        top: `${currentLineOffsetTop - 4}px`,
                                        width: '8px',
                                        height: `${nextLineTopOffset - currentLineOffsetTop + 8}px`,
                                        backgroundColor: hovered ? '#E0E7FF' : 'transparent',
                                      }}
                                    >
                                      <div
                                        style={{
                                          zIndex: 2,
                                          height: 'calc(100% - 8px)',
                                        }}
                                        className='absolute bg-fuchsia-400 w-[1px] top-[4px] left-[4px]'
                                      ></div>
                                    </div>
                                  )
                                );
                              })()}
                            </Fragment>
                          )}
                        </TargetPopover>
                      </Fragment>
                    );
                  }
                }
              }
              return null;
            })}
          </div>
        )}
      </Context.Consumer>
    </MouseTracker>
  );
};

interface PopoverProps {
  isParentModal: boolean;
  offset?: number;
  offsetCross?: number;
  content: (props: { open: boolean; close: () => void }) => ReactNode;
  children: (props: { open: boolean }) => JSX.Element;
  coords?: { x: number; y: number };
  setIsOpened?: (value: boolean) => void;
  disabled?: boolean;
}

const Popover = forwardRef((props: PopoverProps, ref) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.setIsOpened?.(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    placement: 'right',
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: props.offset ?? 50, ...(props.offsetCross ? { crossAxis: props.offsetCross } : {}) }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  const mergedRefs = useMergeRefs([ref, reference]);

  const floatingWrapper = (children: ReactNode) => {
    return props.isParentModal ? (
      <FloatingOverlay className='z-[5]'>{children}</FloatingOverlay>
    ) : (
      <FloatingPortal>{children}</FloatingPortal>
    );
  };
  return (
    <>
      {isValidElement(props.children({ open })) && cloneElement(props.children({ open }), getReferenceProps({ ref: mergedRefs }))}
      <AnimatePresence>
        {open &&
          floatingWrapper(
            <motion.div
              transition={{ type: 'spring', duration: 0.5 }}
              {...{
                initial: { opacity: 0 },
                animate: { opacity: 1 },
                exit: { opacity: 0 },
              }}
              {...getFloatingProps({
                ref: floating,
                className: `max-w-xl pointer-events-auto ${props.disabled ? 'pointer-events-none' : 'pointer-events-auto'}`,
                style: {
                  zIndex: 4,
                  position: strategy,
                  top: y ?? '',
                  left: x ?? '',
                },
              })}
            >
              {props.content({ open, close: () => setOpen(false) })}
            </motion.div>,
          )}
      </AnimatePresence>
    </>
  );
});
