import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikContextType, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { GenericNode, ProductMapping, ProductV3, extractedDataTags, getProductMapping } from '../../../../api';

interface Props {
  productFormik: FormikContextType<ProductV3>;
}

export const ExtractedData = (props: Props) => {
  const formik = useFormikContext<GenericNode>();
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState<ProductMapping[]>();
  const show =
    formik.values.id &&
    props.productFormik.values.metadata.system.some(
      ({ path, tags }) => path.endsWith(formik.values.id) && tags.some((tag) => extractedDataTags.includes(tag)),
    );

  useEffect(() => {
    if (show) {
      getProductMapping(props.productFormik.values.id, formik.values.id).ok(({ mappings }) => setData(mappings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) {
    return <></>;
  }

  return (
    <div
      className='cursor-pointer col-span-2 flex flex-col gap-4 p-4 rounded-lg shadow-[0_0_3px_rgba(0,0,0,0.25)]'
      onClick={() => setExpanded(!expanded)}
    >
      <div className='leading-none w-full flex items-center justify-between text-base font-semibold text-neutral-900'>
        <div>Extracted data</div>
        <FontAwesomeIcon icon={expanded ? solid('chevron-up') : solid('chevron-down')} />
      </div>
      {expanded &&
        data?.map(({ original, comment }, i) => (
          <div key={i} className='flex flex-col gap-2.5'>
            {comment && <div className='text-zinc-500'>{comment}</div>}
            <div className='flex justify-between gap-5'>
              {original.map(({ name, value }, j) => (
                <div key={j} className='flex gap-2.5'>
                  <div className='text-zinc-500'>{name}</div>
                  <div className='text-zinc-600'>{value}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};
