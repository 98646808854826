import { forwardRef, Fragment, useState } from 'react';
import { TimeframeDisplay } from '../../../components/TimeframeDisplay';
import { Methodology } from '../../../../Prototype/types';
import { simplify, roundToShort } from '../../../../shared';
import cn from 'classnames';
import { BaselinePefReport, HistoricalPefReport } from '../../../../../../api';

interface Props {
  title: string;
  methodology: Methodology;
  baseline?: BaselinePefReport;
  historical?: HistoricalPefReport;
  selectedImpact: { id: string; name: string };
  selectedType: 'physical' | 'impactPoints';
}

export const Overview = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [categories, setCategories] = useState(true);
  const data = props.baseline ?? props.historical;

  if (!data) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
        <div className='flex gap-x-6'>
          <div
            className='loading-skeleton'
            style={{
              height: '134px',
              width: '150px',
              borderRadius: '1rem',
            }}
          />
          <div
            className='loading-skeleton'
            style={{
              height: '134px',
              width: '677px',
              borderRadius: '1rem',
            }}
          />
        </div>
      </div>
    );
  }

  const getTotalImpactValue = () => {
    const impact = data.analysis.impactStageMatrix.find((impact) => impact.impactId === props.selectedImpact.id)!;

    return {
      value:
        props.selectedImpact.id === 'overall'
          ? data.impactPoints
          : props.selectedType === 'impactPoints'
          ? impact.impactPoints
          : impact.physicalValue,
      unit: props.selectedType === 'impactPoints' ? 'Impact p.' : impact?.unit,
    };
  };

  return (
    <div className='flex flex-col gap-4' ref={ref}>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        <TimeframeDisplay start={new Date(data.startDate)} end={new Date(data.endDate)} />
      </div>
      <div className='flex flex-1 gap-x-6'>
        <div className='whitespace-nowrap flex flex-col self-start gap-4 p-4 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)]'>
          <div className='text-zinc-800 text-base font-semibold'>Latest impact</div>
          <div className='flex gap-3'>
            <div className='flex-1 flex flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                <div className='text-3xl text-zinc-900'>{simplify(getTotalImpactValue().value)}</div>
                <div className='uppercase tracking-uppercase text-zinc-500 text-xs'>{getTotalImpactValue().unit}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-1 gap-4 p-4 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)]'>
          <button
            className='max-w-32 shrink-0 leading-tight grid grid-cols-1 items-stretch gap-2 -m-2 mr-0'
            onClick={() => setCategories((current) => !current)}
          >
            <div
              className={cn(
                'flex text-center items-center rounded-2xl transition',
                categories ? 'text-brandDarkPurple2 bg-indigo-50' : 'text-zinc-500 bg-slate-50',
              )}
            >
              Category top contributors
            </div>
            <div
              className={cn(
                'flex text-center items-center rounded-2xl transition',
                !categories ? 'text-orange-900 bg-orange-100' : 'text-zinc-500 bg-slate-50',
              )}
            >
              Process top contributors
            </div>
          </button>
          <div className='grid grid-cols-[1fr_1px_1fr_1px_1fr] gap-4'>
            {categories
              ? data.analysis.impactStageMatrix.slice(0, 3).map((impact, i) => (
                  <Fragment key={i}>
                    {i > 0 && <div className='bg-zinc-200' />}
                    <div className='flex flex-col justify-between'>
                      <div className='font-semibold text-zinc-800'>{impact.impactName}</div>
                      <div className='text-2xl text-zinc-800'>{roundToShort(impact.absSharePercent)}%</div>
                      <div className='whitespace-nowrap flex gap-1 items-center'>
                        <div className='text-zinc-600 text-base'>{simplify(impact.physicalValue)}</div>
                        <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>{impact.unit}</div>
                      </div>
                    </div>
                  </Fragment>
                ))
              : data.analysis.lifecycleStageImpacts
                  .flatMap((stage) =>
                    stage.components.flatMap((component) =>
                      component.components.flatMap((contributor) => ({
                        name: contributor.name,
                        type: component.name,
                        share: contributor.impactSharePercent,
                      })),
                    ),
                  )
                  .sort((a, b) => b.share - a.share)
                  .slice(0, 3)
                  .map((process, i) => (
                    <Fragment key={i}>
                      {i > 0 && <div className='w-px shrink-0 bg-zinc-200' />}
                      <div className='grid grid-rows-3 justify-between'>
                        <div className='font-semibold text-zinc-800'>{process.type}</div>
                        <div className='text-zinc-600 line-clamp-2'>{process.name}</div>
                        <div className='text-2xl text-zinc-800'>{roundToShort(process.share)}%</div>
                      </div>
                    </Fragment>
                  ))}
          </div>
        </div>
      </div>
    </div>
  );
});
