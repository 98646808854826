import {
  createContext,
  createRef,
  CSSProperties,
  Fragment,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { eachMonthOfInterval, format, isAfter, isSameMonth } from 'date-fns';
import { simplify, roundToLong } from '../../Root/Main/shared';
import { TargetType } from '../../api';
import cn from 'classnames';

const Context = createContext<number>(Infinity);

interface Props {
  currentWorkspace: string;
  data: TrackingData[];
  selectedTimeframeTick: TimeframeTick | undefined;
  setSelectedTimeframeTick: (value: SetStateAction<TimeframeTick | undefined>) => void;
}

export interface TrackingData {
  isCurrentWorkspace: boolean;
  workspaceSid: string;
  workspaceName: string;
  colors: {
    main: string;
    shade: string;
    empty: string;
    hex: string;
  };
  reports: {
    start: Date;
    end: Date;
    points: number;
    physical?: number;
  }[];
  gapsBetweenReports: {
    start: Date;
    end: Date;
    points: number;
    physical?: number;
  }[];
  timeframe: {
    xAxis: {
      startDate: Date;
      endDate: Date;
    };
    yAxis: {
      points: number;
      physical?: number;
    };
  };
  estimation?: {
    type: 'sufficient' | 'insufficient';
    start: {
      date: string;
      dailyImpact: {
        points: number;
        physical?: number;
      };
      totalPoints: number;
    };
    end: {
      date: string;
      dailyImpact: {
        points: number;
        physical?: number;
      };
      totalPoints: number;
    };
  };
  target?: {
    estimate: string;
    type: TargetType;
    start: {
      date: Date;
      dailyImpact: {
        points: number;
      };
      totalPoints: number;
    };
    end: {
      date: Date;
      dailyImpact: {
        points: number;
      };
      totalPoints: number;
    };
  };
}

export interface TimeframeTick {
  date: Date;
  data: {
    colors: {
      main: string;
      shade: string;
      empty: string;
      hex: string;
    };
    workspaceSid: string;
    workspaceName: string;
    isCurrentWorkspace: boolean;
    estimation?: {
      start: {
        date: Date;
        points: number;
        totalPoints: number;
      };
      end: {
        date: Date;
        points: number;
        totalPoints: number;
      };
    };
    target?: {
      estimate: string;
      type: TargetType;
      start: {
        date: Date;
        dailyImpact: {
          points: number;
        };
        totalPoints: number;
      };
      end: {
        date: Date;
        dailyImpact: {
          points: number;
        };
        totalPoints: number;
      };
      //targetBadge: any;
    };
    report?: {
      start: Date;
      end: Date;
      points: number;
      isBaselineReport: boolean;
      isLastReport: boolean;
    };
    gap?: {
      start: Date;
      end: Date;
      points: number;
    };
  }[];
}

export const ProgressChart = (props: Props) => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const targetsRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const estimationRefs = useRef<
    {
      name: string;
      start?: RefObject<HTMLDivElement>;
      end?: RefObject<HTMLDivElement>;
    }[]
  >([]);
  const gapAreaRefs = useRef<Record<string, RefObject<HTMLDivElement>>[]>([]);
  const [, reRerender] = useReducer((x) => x + 1, 0);
  const [positionX, setPositionX] = useState<number>(Infinity);

  const getRangeByMonth = (start: Date, end: Date) =>
    isAfter(start, end) ? eachMonthOfInterval({ end: start, start: end }) : eachMonthOfInterval({ start, end });

  const timeframe: TimeframeTick[] = eachMonthOfInterval({
    start: new Date('2018-01-01'),
    end: new Date('2025-12-31'),
  }).map((d) => ({
    date: d,
    data: props.data.map((brand) => ({
      workspaceSid: brand.workspaceSid,
      workspaceName: brand.workspaceName,
      isCurrentWorkspace: brand.isCurrentWorkspace,
      colors: brand.colors,
      report: brand.reports
        .map((report, i) => ({
          ...report,
          isBaselineReport: i === 0,
          isLastReport: i === brand.reports.length - 1,
          monthlyPoints: report.points / getRangeByMonth(report.start, report.end).length,
        }))
        .find((report) => getRangeByMonth(report.start, report.end).find((date) => isSameMonth(date, d))),
      gap: brand.gapsBetweenReports.find((gap) => getRangeByMonth(gap.start, gap.end).find((date) => isSameMonth(date, d))),
      estimation: brand.estimation
        ? {
            start: {
              date: new Date(brand.estimation.start.date),
              points: brand.estimation.start.dailyImpact.points,
              totalPoints: brand.estimation.start.totalPoints,
            },
            end: {
              date: new Date(brand.estimation.end.date),
              points: brand.estimation.end.dailyImpact.points,
              totalPoints: brand.estimation.end.totalPoints,
            },
          }
        : undefined,
      target: brand.target
        ? {
            ...brand.target,
            start: {
              ...brand.target?.start,
              date: new Date(brand.target!.start.date),
            },
            end: {
              ...brand.target?.end,
              date: new Date(brand.target!.end.date),
            },
          }
        : undefined,
    })),
  }));

  useEffect(() => {
    props.data.forEach((item, i) => {
      targetsRefs.current[i] = targetsRefs.current[i] ?? {
        name: item.workspaceName,
        start: createRef(),
        end: createRef(),
      };
      estimationRefs.current[i] = estimationRefs.current[i] ?? {
        name: item.workspaceName,
        start: createRef(),
        end: createRef(),
      };
    });

    timeframe.forEach((item, i) => {
      gapAreaRefs.current[i] = gapAreaRefs.current[i] ?? item.data.map(() => createRef());
    });
    reRerender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, canvasRef.current]);

  useEffect(() => {
    window!.addEventListener('resize', reRerender);

    return () => {
      window!.removeEventListener('resize', reRerender);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe]);

  const totalImpactPoints = Math.max(
    ...timeframe.map((tick) =>
      tick.data.reduce((acc, item) => {
        if (item.report && getRangeByMonth(item.report.start, item.report.end).find((date) => isSameMonth(date, tick.date))) {
          return acc + item.report.points;
        }
        if (item.gap && !item.report && getRangeByMonth(item.gap.start, item.gap.end).find((date) => isSameMonth(date, tick.date))) {
          return acc + item.gap.points;
        }
        return acc;
      }, 0),
    ),
  );

  const yDomain = () => {
    const values = props.data.map((item) => [...item.reports].flatMap((item) => item.points));
    const yMin = Math.min(...values.map((item) => Math.min(...item)));

    return { yMin, yMax: totalImpactPoints * 1.1 };
  };

  const yMax = yDomain().yMax;
  const yMin = yDomain().yMin;

  const yAxisTicks = () => (
    <div className='absolute h-full left-0 right-0 -z-[2]'>
      <div className='absolute h-full w-full flex flex-col justify-between bottom-0 border-t divide-y'>
        {new Array(10).fill(null).map((_, i, arr) => {
          const rawValue = yMax - ((yMax - yMin) / (arr.length - 1)) * i;
          return (
            <div className='w-full' key={i}>
              <div className='absolute left-0'>
                <div title={roundToLong(rawValue)} className='-translate-y-1/2 -translate-x-full pr-1.5 text-xs text-zinc-500'>
                  {simplify(rawValue)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const xAxisTicks = (i: number, month: Date) =>
    i % 12 === 0 && (
      <>
        <div className='absolute -bottom-5 text-xs text-zinc-500 whitespace-nowrap -translate-x-1/2'>{format(month, 'yyyy')}</div>
        <div className='absolute bottom-0 top-0 border-r' />
      </>
    );

  const renderGapAreas = () =>
    gapAreaRefs.current.map((ref: any) =>
      ref.map(
        (gap: RefObject<HTMLDivElement>, k: number) =>
          gap.current && (
            <Fragment key={k}>
              {props.data.map((item, i) => (
                <defs key={i}>
                  <pattern id={`pattern_${i}`} patternUnits='userSpaceOnUse' width='6' height='6' patternTransform='rotate(45)'>
                    <line x1='0' y='0' x2='0' y2='6' strokeWidth='4' stroke={item.colors.hex} />
                  </pattern>
                </defs>
              ))}
              <rect
                fill={`url(#pattern_${k})`}
                x={gap.current.offsetLeft}
                y={gap.current.offsetTop}
                width={gap.current.offsetWidth}
                height={gap.current.offsetHeight}
              />
            </Fragment>
          ),
      ),
    );

  const renderEstimations = () =>
    estimationRefs.current.map(
      (ref, i) =>
        ref.start?.current &&
        ref.end?.current && (
          <line
            key={i}
            x1={ref.start.current.offsetLeft + ref.start.current.offsetWidth}
            x2={ref.end.current.offsetLeft + ref.end.current.offsetWidth}
            y1={ref.start.current.offsetTop + 4}
            y2={ref.end.current.offsetTop}
            stroke='#a1a1ab75'
            strokeDasharray={4}
            strokeWidth={2}
          ></line>
        ),
    );

  const renderTargets = () => {
    return targetsRefs.current.map((ref: any, i) => {
      return (
        ref.start.current &&
        ref.end.current && (
          <line
            key={i}
            x1={ref.start.current.offsetLeft + ref.start.current.offsetWidth}
            x2={ref.end.current.offsetLeft + ref.end.current.offsetWidth}
            y1={ref.start.current.offsetTop + 4}
            y2={ref.end.current.offsetTop}
            stroke='#e879f975'
            strokeDasharray={4}
            strokeWidth={2}
          ></line>
        )
      );
    });
  };

  return (
    <MouseTracker>
      <div className='absolute top-0 left-0 right-0 bottom-0 z-[2]'>
        <svg width='100%' height='100%'>
          {renderGapAreas()}
          {renderEstimations()}
          {renderTargets()}
        </svg>
      </div>
      <Context.Consumer>
        {(cursorPosition) => {
          return (
            <div className='relative h-full z-[3]'>
              {yAxisTicks()}
              <div ref={canvasRef} className='grid grid-flow-col items-end h-full relative border-r border-t z-[2]'>
                {props.selectedTimeframeTick && (
                  <div
                    onClick={() => props.setSelectedTimeframeTick(undefined)}
                    style={{ '--cursor-x': `${positionX}px` } as CSSProperties}
                    className={cn('absolute top-0 bottom-0 w-[1px] bg-brand translate-x-[--cursor-x] z-[3]')}
                  >
                    <div className='absolute left-0 -translate-x-[64%] top-1/2 -rotate-90 whitespace-nowrap text-brandDark text-xs font-semibold'>
                      {props.selectedTimeframeTick && format(new Date(props.selectedTimeframeTick.date), 'MMMM yyyy')}
                    </div>
                  </div>
                )}

                {timeframe.map((timeframeTick, monthIndex, tfArr) => (
                  <div
                    onClick={() => {
                      setPositionX(cursorPosition);
                      props.setSelectedTimeframeTick(timeframeTick);
                    }}
                    key={monthIndex}
                    className='flex flex-col-reverse h-full z-[1]'
                  >
                    {xAxisTicks(monthIndex, timeframeTick.date)}
                    {timeframeTick.data.map((item, j) => {
                      const gapRef = gapAreaRefs.current[monthIndex] && gapAreaRefs.current[monthIndex][j];

                      return (
                        <Fragment key={j}>
                          {(() => {
                            if (item.isCurrentWorkspace) {
                              if (item.target && monthIndex === tfArr.length - 1 && isAfter(item.target.end.date, timeframeTick.date)) {
                                const totalRange = eachMonthOfInterval({
                                  start: item.target.start.date,
                                  end: item.target.end.date,
                                }).length;
                                const visibleRange = eachMonthOfInterval({
                                  start: item.target.start.date,
                                  end: timeframeTick.date,
                                }).length;

                                const targetEndPoints =
                                  item.target.start.totalPoints +
                                  (item.target.end.totalPoints - item.target.start.totalPoints) * (visibleRange / totalRange);

                                return (
                                  <div
                                    ref={(targetsRefs.current[j] as any)?.end}
                                    style={
                                      {
                                        '--target-end': `${((item.target.start.totalPoints - targetEndPoints) / yMax) * 100}%`,
                                      } as CSSProperties
                                    }
                                    className='flex self-end absolute bg-brandDarkPurple2 bottom-[--target-end]'
                                  />
                                );
                              } else if (item.target && isSameMonth(item.target.end.date, timeframeTick.date)) {
                                return (
                                  <div
                                    ref={(targetsRefs.current[j] as any)?.end}
                                    style={{ '--target-end': `${(item.target.end.totalPoints / yMax) * 100}%` } as CSSProperties}
                                    className='absolute bg-brandDarkPurple2 bottom-[--target-end]'
                                  />
                                );
                              }

                              if (
                                item.estimation?.end &&
                                monthIndex === tfArr.length - 1 &&
                                isAfter(item.estimation.end.date, timeframeTick.date)
                              ) {
                                const totalRange = eachMonthOfInterval({
                                  start: item.estimation.start!.date,
                                  end: item.estimation.end.date,
                                }).length;
                                const visibleRange = eachMonthOfInterval({
                                  start: item.estimation.start!.date,
                                  end: timeframeTick.date,
                                }).length;

                                const estimationEndPoints = item.estimation.start!.totalPoints * (visibleRange / totalRange);

                                return (
                                  <div
                                    ref={estimationRefs.current[j]?.end}
                                    style={
                                      {
                                        '--estimation-end': `${((item.estimation.start!.totalPoints - estimationEndPoints) / yMax) * 100}%`,
                                      } as CSSProperties
                                    }
                                    className='flex self-end absolute bg-green-600 bottom-[--estimation-end]'
                                  />
                                );
                              } else if (item.estimation?.end && isSameMonth(timeframeTick.date, item.estimation.end.date)) {
                                return (
                                  <div
                                    ref={(estimationRefs.current[j] as any)?.end}
                                    style={
                                      {
                                        '--estimated-end': `${(item.estimation.end.totalPoints / yMax) * 100}%`,
                                      } as CSSProperties
                                    }
                                    className='absolute bg-green-600 bottom-[--estimated-end]'
                                  />
                                );
                              }
                            }
                          })()}
                          {(() => {
                            const currentWorkspaceBaseline =
                              item.isCurrentWorkspace && item.report?.isBaselineReport ? item.report : undefined;
                            if (item.report) {
                              return (
                                <div
                                  ref={
                                    item.estimation?.start && isSameMonth(timeframeTick.date, item.estimation.start.date)
                                      ? (estimationRefs.current[j] as any)?.start
                                      : undefined
                                  }
                                  style={{ '--share-height': `${(item!.report?.points / yMax) * 100}%` } as CSSProperties}
                                  className={cn('h-[--share-height]', item.colors.shade)}
                                >
                                  <div
                                    ref={
                                      item.target && isSameMonth(new Date(item.target.start.date), timeframeTick.date)
                                        ? (targetsRefs.current[j] as any)?.start
                                        : undefined
                                    }
                                    className='relative flex items-center h-1'
                                  >
                                    {currentWorkspaceBaseline ? (
                                      (() => {
                                        return isSameMonth(new Date(item.report.start), timeframeTick.date) ? (
                                          <>
                                            <div className='absolute border-l-[5px] border-l-brandDarkPurple2 border-t-[4px] border-b-[4px] border-r-brandDarkPurple2 border-transparent'></div>
                                            <div className='absolute w-full h-[2px] bg-brandDarkPurple2'></div>
                                          </>
                                        ) : isSameMonth(new Date(item.report.end), timeframeTick.date) ? (
                                          <>
                                            <div className='absolute right-0 border-r-[5px] border-r-brandDarkPurple2 border-t-[4px] border-b-[4px] border-transparent'></div>
                                            <div className='absolute w-full h-[2px] bg-brandDarkPurple2'></div>
                                          </>
                                        ) : (
                                          <div className='bg-brandDarkPurple2 h-[2px] w-full'></div>
                                        );
                                      })()
                                    ) : (
                                      <div className={cn('h-1 w-full', item.colors.main)}></div>
                                    )}
                                  </div>
                                </div>
                              );
                            } else if (item.gap) {
                              return (
                                <div
                                  ref={gapRef}
                                  style={
                                    {
                                      '--gap-height': `${(item.gap.points / yMax) * 100}%`,
                                    } as CSSProperties
                                  }
                                  className={cn('h-[--gap-height]', item.colors.empty)}
                                >
                                  <div className='h-1' />
                                </div>
                              );
                            }
                            return undefined;
                          })()}
                        </Fragment>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </Context.Consumer>
    </MouseTracker>
  );
};

/*const BaselineMarker = forwardRef<
  HTMLDivElement,
  {
    start: Date;
    end: Date;
    comparisonDate: Date;
  }
>((props, ref) => {
  return isSameMonth(new Date(props.start), props.comparisonDate) ? (
    <>
      <div className='absolute border-l-[5px] border-l-brandDarkPurple2 border-t-[4px] border-b-[4px] border-r-brandDarkPurple2 border-transparent'></div>
      <div ref={(ref as any)?.start} className='absolute w-full h-[2px] bg-brandDarkPurple2'></div>
    </>
  ) : isSameMonth(new Date(props.end), props.comparisonDate) ? (
    <>
      <div className='absolute right-0 border-r-[5px] border-r-brandDarkPurple2 border-t-[4px] border-b-[4px] border-transparent'></div>
      <div ref={(ref as any)?.start} className='absolute w-full h-[2px] bg-brandDarkPurple2'></div>
    </>
  ) : (
    <div className='bg-brandDarkPurple2 h-[2px] w-full'></div>
  );
});*/

const MouseTracker = (props: PropsWithChildren<{}>) => {
  const ref = createRef<HTMLDivElement>();
  const [position, setPosition] = useState<number>(Infinity);
  const animationFrame = useRef<number>(Infinity);

  useEffect(() => {
    const wrapper = ref.current!;

    if (wrapper) {
      const mousemove = ({ x }: MouseEvent) => {
        animationFrame.current = requestAnimationFrame(() => setPosition(x - wrapper.getBoundingClientRect().left));
      };

      wrapper.addEventListener('mousemove', mousemove);

      return () => {
        wrapper.removeEventListener('mousemove', mousemove);
        cancelAnimationFrame(animationFrame.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <Context.Provider value={position}>
      <div onMouseLeave={() => setPosition(Infinity)} ref={ref} className='relative h-full'>
        {props.children}
        <div
          style={
            {
              transform: `translateX(${position}px)`,
            } as CSSProperties
          }
          className={cn('absolute top-0 bottom-0 w-[1px] bg-zinc-300 z-[2]', {
            hidden: position === Infinity || position < 0,
          })}
        />
      </div>
    </Context.Provider>
  );
};
